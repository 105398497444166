* {

  margin: 0;
  padding: 0;

}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

a {
  text-decoration: none;
}

.undr {
  text-decoration: underline;
  font-weight: bold;
}

.main-space {
  font-family: 'Montserrat';
  font-weight: 400;
  background: #F5F5F5;
  display: flex;
  justify-content: center;
  width: 100%;
}

.space {
  width: 20%;
  height: 100vh;
  background: #F5F5F5;
}

.logo {
  width: auto;
  height: 120px;
  margin-left: 50px;
}

header {
  display: flex;
  align-items: center;
  height: 150px;
  position: fixed;
  top: 0;
  z-index: 999;
  background: white;
  width: 1300px;
  box-shadow: 0px 4px 30px rgba(128, 128, 128, 0.192);
}

.header-space {

  width: 100%;
  height: 150px;
  z-index: 0;

}

.nav-btn {
  color: #F47AE1;
  text-decoration: none;
  margin-right: 50px;
  font-size: 30px;
  font-weight: 400;
}

.nav-style {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main {
  width: 1300px;
  background: white;
  height: 100%;
  box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.spinner {
  display: flex;
  height: 650px;
  width: 100%;
  background-image: url("./images/photo1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  border-top: 0.5px solid #00000056;
  border-bottom: 0.5px solid #00000056;
}

.text-under-spinner {

  width: 100%;
  height: auto;
  background: rgba(35, 35, 35, 80%);
  padding: 20px 0px;
  display: none;
  font-size: 15px;
  color: #D0C1C1;
  text-align: center;
}

.black-div {
  width: 45%;
  height: 100%;
  background: rgba(35, 35, 35, 0.568);
  transform: skew(-20deg, 0deg) translate(-138px);
}

.masters {
  height: 900px;
  background: #ffffff;
  margin: 100px 50px 0px 50px;
  box-shadow: 6px 9px 38px rgba(0, 0, 0, 0.15);
  box-shadow: -4px 0px 38px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.font-head {
  color: #F47AE1;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
}

.card {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.5px solid #000000;
  width: 478px;
  height: 172px;
  border-radius: 28px;
}

.black-div-text {
  width: 450px;
  margin-left: 200px;
  margin-top: 100px;
  font-size: 32px;
  color: #D0C1C1;
  line-height: 2.3;
  font-style: thin;
  transform: skew(20deg, 0deg);
  font-weight: 300;

}

.black-div-btn {

  width: 300px;
  height: 75px;
  background: #DCD8D8;
  transform: skew(20deg, 0deg);
  margin-left: 225px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #4E4D4D;
  font-weight: 400;
  transition: 0.2s;
}

.pers-icon {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

.card-text {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 120px;
  border-right: 1px solid black;
}

.card-top-text {
  height: 70px;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  line-height: 1.5;
}

.card-bottom-text {
  height: 70px;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: flex-end;
}

.card-phone {
  width: 80px;
  height: 80px;
}

.card-phone-div {
  margin-left: 15px;
  width: 150px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: phone 2s infinite linear;

}

@keyframes phone {
  10% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(-10deg);
  }

  30% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  100% {}
}

.service {
  height: auto;
  margin: 0px 50px 150px 50px;
  box-shadow: 6px 9px 38px rgba(0, 0, 0, 0.15);
  box-shadow: -4px 0px 38px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.font-head-service {
  color: #F47AE1;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
}

.scissors-service {

  position: absolute;
  margin-left: -900px;
  margin-top: -60px;

}

.service-card {

  display: flex;
  justify-content: center;
  width: 480px;
  height: 543px;
  border: 0.5px solid black;
  margin-top: 0px;
  margin-bottom: 30px;
  border-radius: 10px;
  flex-wrap: wrap;

}

.comb {

  width: 100%;
  display: flex;
  justify-content: flex-end;

}

.comb-image {

  margin-bottom: 20px;
  margin-right: 20px;

}

.service-card-top {
  display: flex;
  font-size: 30px;
  font-weight: 300;
  width: 90%;
  height: 70px;
  border-bottom: 1px solid black;
  align-items: center;
  justify-content: space-around;

}

.service-card-top-text {

  display: flex;
  width: 70%;

}

.service-card-icon {

  display: flex;
  width: 20%;
  align-items: center;
  justify-content: flex-end;

}

.service-card-main-text-left {
  font-size: 20px;
  height: 463px;
  width: 70%;


}

li {
  margin-left: 40px;
  margin-top: 40px;
  margin-bottom: 20px;

}

.service-card-main-text-right {
  text-align: right;
  font-size: 20px;
  list-style-type: none;
  height: 463px;
  width: 25%;
}

.service-card-space-right {

  width: 5%;

}

.non-dec {

  list-style-type: none;

}

.contacts {
  height: auto;
  margin: 0px 50px 100px 50px;
  box-shadow: 6px 9px 38px rgba(0, 0, 0, 0.15);
  box-shadow: -4px 0px 38px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.font-head-contacts {
  color: #F47AE1;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;

  font-weight: bold;
}

.map {
  width: 100%;
  height: 100%;
  border: none;
}

.map-div {
  background-repeat: no-repeat;
  background-size: cover;
  background: url("./images/photo2.jpg");
  margin-bottom: 80px;
  width: 50%;
  height: 330px;
  border: none;

}

.contacts-text {
  text-align: right;
  font-size: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 35%;
  height: 400px;


}

.contacts-text-top {

  width: 100%;
  height: auto;

}

.contacts-text-middle {

  width: 100%;
  height: auto;

}

.contacts-text-bottom {


  width: 100%;
  height: auto;

}

.span-dec {

  font-weight: bold;
  text-decoration: underline;

}

footer {
  display: flex;
  font-size: 20px;
  color: #00000055;
  height: 50px;
  margin: 50px 50px 50px 50px;
  justify-content: center;
  text-align: center;

}

.call-btn-width {
  display: flex;
  text-align: center;
  display: none;
  align-items: center;
  border: 1px solid #F47AE1;
  border-radius: 20px;

}

.phone-in-button {
  width: 17px;
  height: 17px;
  margin: 0px 5px 0px 8px;
  animation: 2s phone infinite linear;
}

.phone-align {
  display: flex;
  align-items: center;
}

#m-phone-num {
  font-size: 20px;
  margin: 0px 8px 0px 0px;
}

@media only screen and (max-width: 1300px) {

  .scissors-service {
    display: none;
  }

  .comb {
    display: none;
  }

  .main {
    width: 100%;
  }

  header {
    width: 100%;
  }

  .card {

    margin-bottom: 30px;

  }

  .masters {

    height: auto;

  }

}

@media only screen and (max-width: 1200px) {

  .spinner {

    height: 480px;

  }

  .text-under-spinner {

    background: #000000a4;

  }

  .service-card {
    height: auto;
    margin: 0px 10px 20px 10px;
  }

  .service-card-main-text-left {
    font-size: 20px;
    height: auto;
    width: 70%;
  }

  .service-card-main-text-right {
    font-size: 20px;
    height: auto;
    width: 25%;
  }

  .nav-btns {
    display: none;
  }

  .text-under-spinner {
    display: block;
  }

  .black-div-text {
    display: none;
  }

  .black-div {
    display: none;
  }


}

@media only screen and (max-width: 650px) {

  .map-div {
    margin-bottom: 80px;
    width: 100%;
    height: 330px;
    border: none;

  }

  .contacts-text {

    display: block;
    height: 290px;

  }

  .contacts-text-top {

    font-size: 19px;
    height: 80px;

  }

  .contacts-text-middle {

    font-size: 20px;
    height: 80px;
  }

  .contacts-text-bottom {

    font-size: 20px;
    height: 50px;

  }

  .spinner {

    height: 250px;

  }



  .font-head {

    margin-top: -50px;
    margin-bottom: -50px;

  }

  .font-head-service {

    margin-top: -80px;
    margin-bottom: -50px;

  }

  .font-head-contacts {

    margin-top: -80px;
    margin-bottom: -50px;

  }

  .contacts {

    box-shadow: 0px 0px 0px;
    flex-direction: column;
    align-items: center;

  }

  .map-div {

    width: 100%;

  }

  .contacts-text {
    font-size: 25px;
    text-align: center;

  }

  .card {
    width: 500px;
    height: 150px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 30px;
  }

  .card-top-text {

    font-size: 20px;

  }

  .pers-icon {
    width: 80px;
    height: 80px;
  }

  .card-text {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100px;
    border-right: 1px solid black;
  }

  .card-top-text {
    height: 70px;
    font-size: 15px;
    font-weight: bold;
    color: #000000;
    line-height: 1.5;
  }

  .card-bottom-text {
    height: 70px;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    align-items: flex-end;
  }

  .card-phone {
    width: 50px;
    height: 50px;
  }

  .card-phone-div {
    margin-left: 15px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .masters {
    margin: 0px;
    height: auto;
    box-shadow: 0px 0px 0px;

  }

  .service {

    box-shadow: 0px 0px 0px;
    margin: 0px;
    height: auto;
  }

  .contacts {
    margin: 0px;
    height: auto;

  }

  .nav-btns {

    display: none;

  }

  #masters {

    display: none;

  }

  #service {

    display: none;

  }


}

@media only screen and (max-width: 510px) {

  .contacts-text {
    width: 250px;

    display: block;
    height: 250px;

  }

  .contacts-text-top {

    font-size: 23px;
    height: 90px;

  }

  .contacts-text-middle {

    font-size: 23px;
    height: 90px;
  }

  .contacts-text-bottom {

    font-size: 23px;
    height: 50px;

  }

  .contacts-text {
    font-size: 20px;
    text-align: center;

  }

  .spinner {

    height: 200px;

  }

  .logo {

    width: 150px;
    height: 75px;

  }

  header {
    height: 135px;
    display: flex;
    flex-direction: column;

  }

  .nav-style {

    flex-direction: column;
  }

  .nav-btn {

    margin: 0;
    padding: 0;

  }

  .call-btn-width {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    width: auto;
    height: auto;
  }

  header {
    justify-content: center;
  }

  .nav-style {

    display: none;

  }

  .logo {
    margin: 0;
  }

  #btn-hide {
    display: none;
  }

  .card {
    width: 400px;
    height: 150px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 30px;
  }

  .pers-icon {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
  }

  .card-text {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 100px;
    border-right: 1px solid black;
  }

  .card-top-text {
    height: 70px;
    font-size: 15px;
    font-weight: bold;
    color: #000000;
    line-height: 1.5;
  }

  .card-bottom-text {
    height: 70px;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    align-items: flex-end;
  }

  .card-phone {
    width: 50px;
    height: 50px;
  }

  .card-phone-div {
    margin-left: 15px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-btns {

    display: none;

  }

  li {
    font-size: 15px;
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 20px
  }

  .service {
    margin: 0px 10px 0px 10px;
  }

  .li-margin {
    margin: 20px 0px;
  }

  .header-space {

    height: 120px;

  }

}

@media only screen and (max-width: 450px) {

  .card {
    width: 340px;
    height: 150px;
    margin-bottom: 30px;
  }

  .spinner {

    height: 200px;

  }

  .card-phone-div {
    margin-left: 0px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li {

    font-size: 12px;

  }

  .service-card-top-text {
    font-size: 22px;
  }

}